import React from "react"
import Slider from "react-slick"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Animation from "../shared/animation"
import StyledFeaturedCard from "./featuredcard"

const Carousel = ({ className }) => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 5
      ) {
        edges {
          node {
            excerpt(pruneLength: 350)
            fields {
              slug
            }
            frontmatter {
              author
              website
              date(formatString: "MMMM DD, YYYY")
              title
              rating
              tags
              abstract
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  var settings = {
    dots: true,
    dotsClass: "slick-dots relative md:pt-8",
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    centerMode: false,
    className: "",
    // "flex flex-row m-0 self-center font-thin text-3xl content-center",
  }
  return (
    <Slider {...settings}>
      {data.edges.map(n => (
        <StyledFeaturedCard n={n}></StyledFeaturedCard>
      ))}
    </Slider>
  )
}

export default Carousel
