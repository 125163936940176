import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Loader from "react-loader-spinner"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import TextTruncate from "react-text-truncate"
import Next from "../../../content/assets/svgs/next.svg"
import RatingComponent from "../shared/ratings"

const FeaturedCard = ({ n, className }) => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  return (
    <>
      {loading ? (
        <div className="flex w-full justify-center rounded overflow-hidden">
          <div className="w-1/2"></div>
          <Loader type="Watch" color="#2b4162"></Loader>
          <div className="w-1/2"></div>
        </div>
      ) : (
        <div className="flex  w-full content-center rounded overflow-hidden">
          <BackgroundImage
            className={className}
            fluid={n.node.frontmatter.thumbnail.childImageSharp.fluid}
          >
            {/* <div className="w-1/3"></div> */}
            {/* <Text> */}
            <div className="flex p-4 md:p-8 w-auto xl:w-2/5 bg-offwhite leading-tight overflow-hidden  m-8 rounded">
              <div className="flex flex-col justify-between">
                <div className="flex flex-col ">
                  <div class="font-header font-bold text-2xl md:text-4xl">
                    {n.node.frontmatter.title}
                  </div>

                  <p class="text-base md:text-lg lg:text-xl flex items-center">
                    {n.node.frontmatter.author}
                  </p>
                  <div className="py-2">
                    <RatingComponent
                      size="20px"
                      rating={n.node.frontmatter.rating}
                    ></RatingComponent>
                  </div>
                  <p class="tracking-wide text-xs">
                    Published on {n.node.frontmatter.date}
                  </p>
                </div>
                <p class="hidden lg:block py-2 md:text-sm leading-none text-justify md:leading-normal">
                  {n.node.frontmatter.abstract}
                  <br></br>
                </p>

                <TextTruncate
                  line={10}
                  element="span"
                  containerClassName="block lg:hidden text-sm text-justify"
                  truncateText=" ..."
                  text={n.node.frontmatter.abstract}
                />
                <Link to={n.node.fields.slug}>
                  <Next className="h-8 mx-2 my-2 inline-block" />
                  <button className="text-lg inline-block">Read more</button>
                </Link>
              </div>
              {/* <Img
              imgStyle={{ objectFit: "contain", height: "auto", width: "100%" }}
              className="  p-8  mx-auto  items-center"
              fluid={n.node.frontmatter.thumbnail.childImageSharp.fluid}
            ></Img> */}
            </div>

            {/* </Text> */}
            {/* <div className="hidden lg:block lg:max-w-6xl lg:w-full"></div> */}
            {/* <div className="hidden xl:block xl:w-full"></div> */}
          </BackgroundImage>
        </div>
      )}
    </>
  )
}

const Text = styled.div`
  color: White;
  mix-blend-mode: screen;
  background-color: white;
`
const StyledFeaturedCard = styled(FeaturedCard)`
  display: flex;
  overflow: hidden;
  background-repeat: no-repeat;
  border-radius: 0.25rem;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
  width: 100%;
  min-height: 60vh;
  max-height: 60vh;
  /* You should set a background-size as the default value is "cover"! */
  background-size: cover;
  /* So we won't have the default "lightgray" background-color. */
  background-color: transparent;
`

export default StyledFeaturedCard
