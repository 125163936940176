import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/shared/seo"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import Animation from "../components/shared/animation"
import StyledCarousel from "../components/index/carousel"

const Index = ({ className, data, location }) => {
  // const siteTitle = data.site.siteMetadata.title
  // const posts = data.allMarkdownRemark.edges

  // TODO: currently is reviews page content / need to think of index content - blue sky - recent post carousel
  return (
    <Layout location="">
      <StyledCarousel></StyledCarousel>
    </Layout>
  )
}

const StyledInnerWrapper = styled.div`
  margin-top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledIndex = styled(Index)`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  border-radius: 0.25rem;
  width: auto;
  height: auto;
  /* You should set a background-size as the default value is "cover"! */
  background-size: 400px;
  /* So we won't have the default "lightgray" background-color. */
  background-color: transparent;
`

export default Index
